import React, { useEffect, useState, useContext } from 'react'
import { observer } from "mobx-react-lite";
import { RootContext } from "../../index.js";
import { DateInput } from "../Common/Form.js"
import moment from "moment"
import { useParams } from "react-router-dom";
import SimGraph from './simGraph.js';
require ("moment-timezone");

const DataUsageSim = () => {
  const rootStore = useContext(RootContext);
  const dataUsageStore = rootStore.dataUsageStore
  

  const { id } = useParams();
  const [finalValue,setFinalValue] = useState([])
    
  useEffect(() => {
    const fetchData = async() => {
     const data =  await dataUsageStore.fetchSimUsageById(id);
      setFinalValue(data)
    }
    fetchData()
  
  }, [dataUsageStore,id]);

  const [filters, setFilters] = useState({
    date_from: moment().subtract(7, "days").format("YYYY-MM-DD 00:00:00"),
    date_to: moment().format("YYYY-MM-DD 23:59:59")
  });

  const setNewFilters = (filter, value) => {
    const dateTimeValue = moment.utc(value).startOf('day').toISOString();
    setFilters((prev) => ({ ...prev, [filter]: dateTimeValue }));
  };

  const fromDate = moment(filters.date_from);
  const toDate = moment(filters.date_to);


  let data = finalValue && finalValue.filter((item) => {
    let dataTime = moment.utc(item.created_date);
    return dataTime.isBetween(filters.date_from, filters.date_to, null, '[]'); // Using '[]' includes both start and end dates
  }).sort((a,b)=> a.id-b.id);


  let graphValue = []
  if (toDate.diff(fromDate, 'days') > 0) {
    let balance_array = []
    let volume_array = []
    let date_array = []

    data && data.map((item)=>{
      if (item.remaining_balance){
        balance_array.push(item.remaining_balance)
      }
      
      if (item.remaining_volume) {
        volume_array.push(item.remaining_volume)
      }

      if (item.created_date) {
        date_array.push((moment.utc(item.created_date).tz('Asia/Kathmandu')).format('YYYY-MM-DD HH:mm:ss'))
      }
    })

 
    const title = 'Remaining Balance and Volume'
    graphValue = [date_array,balance_array,volume_array,title]
    // console.log(balance_array,"balanceArray");
    // console.log(volume_array,"volume_array");
    // console.log(date_array,"dateArray");
    
  } 


  return (
    <div className='datausage'>
      <div >
        <DateInput
          label={"Date From"}
          type="date"
          value={moment(filters.date_from).format("YYYY-MM-DD")}
          onChange={(e) => setNewFilters("date_from", e.target.value)}
        />
        <DateInput
          label={"Date To"}
          type="date"
          value={moment(filters.date_to).format("YYYY-MM-DD")}
          onChange={(e) => setNewFilters("date_to", e.target.value)}
        />
      </div>

    <br/><br/>
     <SimGraph graphData={graphValue}  />
    </div>


  )
}

export default DataUsageSim