import { useRef, useState, useContext, useEffect } from 'react'
import { useHistory, Link, useLocation } from 'react-router-dom';
import TabBar from './Common/TabBar';
import { observer, inject } from 'mobx-react-lite';
import { action, toJS, autorun, observable } from 'mobx';
import { IconButton } from './Common/Form';
import "../../src/styles/main.css"
import { RootContext } from "../../src/index";
import DeviceList from './Devices/DeviceList';
import DataUsage from './DataUsage/dataUsage';
import { useNavigate, useParams } from "react-router-dom";
import { token } from '../libs/localStorage';
import "../css/dropdownmenu.css"

const NavBar = observer(() => {
    const rootStore = useContext(RootContext);
    const uiStore = rootStore.uiStore;
    const decodedToken = token.getDecoded();
    const [isDropdownVisible, setDropdownVisible] = useState(false);


    const [tabs, setTabs] = useState([])
    const location = useLocation();

    const navigate = useNavigate()
    const { id } = useParams();

    useEffect(() => {
        const disposeAutorun = autorun(() => {
            let updatedTabs = [];

            if (location.pathname.indexOf('/singledevicedetail') === 0) {
                if (id.toString().length == 10) {
                    updatedTabs = [
                        { to: `/singledevicedetail/sim/metadata/${id}`, label: 'Metadata', name: 'Metadata' },
                        // { to: `/singledevicedetail/sim/graph/${id}`, label: 'DataUsage Graph', name: 'datausage' },
                        // { to: ` /singledevicedetail/sim/datausage/detail/${id}`, label: 'DataUsage Detail', name: 'data usage detail' },
                        { to: `/singledevicedetail/sim/datausage/graph/${id}`, label: 'DataUsage', name: 'DataUsage' },
                        { to: `/singledevicedetail/sim/logs/${id}`, label: 'Logs', name: 'Logs' }

                    ];
                } else {
                    updatedTabs = [
                        { to: `/singledevicedetail/orbcomm/metadata/${id}`, label: 'MetaData', name: 'metadata' },
                        { to: `/singledevicedetail/graph/${id}`, label: 'DataUsage Graph', name: 'datausage' },
                    ];
                }


            } else {
                // Default tabs for other routes
                updatedTabs = [
                    { to: '/monitoring', label: 'Monitoring', name: 'devices' },
                    { to: '/configuration', label: 'Configuration', name: 'configuration' }
                ];
            }

            setTabs(updatedTabs);
        });

        return () => {
            disposeAutorun();
        };
    }, [location.pathname]);


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (event.target.closest(".toggle") === null) {
                setDropdownVisible(false);
            }
        };

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    //Decoded Data
    const decodedData = token.getDecoded()

    const toggleNav = () => {
        uiStore.toggleNav();
    };

    let currentLocation = location.pathname;



    return (
        <div>
            <div className="Header" style={uiStore.isNavOpen ? undefined : {paddingLeft:"10px"}}>
                {uiStore.isNavOpen && <IconButton icon='fa fa-arrow-left' onClick={toggleNav} primary />}
                {!uiStore.isNavOpen && <IconButton icon='fa fa-arrow-right' onClick={toggleNav} primary />}
                <IconButton icon='fa fa-home' onClick={() => navigate('/monitoring/dashboard')} primary />
                <p style={{ marginTop: "0px" }}>{id ? id : "Home"}</p>

                <div className="toggle dropbtn" onClick={() => setDropdownVisible(!isDropdownVisible)}>
                    <div className="dropdown">
                        <i className='fa fa-user white dropbtn' />
                        <div id="headerMenu" className={`dropdown-content dropdown-content-left ${isDropdownVisible ? 'show' : ''}`}>
                            <div className='userName'>
                                <i className='fa fa-user-circle' style={{ color: 'black' }} />{decodedToken && decodedToken.user}
                            </div>
                            <a onClick={() => {
                                localStorage.clear();
                                navigate("/login");
                            }}>
                                <i className='fa fa-sign-out' style={{ color: 'black' }} /> Sign Out</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="ContentSection" style={uiStore.isNavOpen ? undefined : {marginLeft:"10px"}} >
                <TabBar
                    items={toJS(tabs)}
                />
                <div className="">
                    {currentLocation.indexOf('/monitoring') === 0 && (
                        <TabBar
                            items={[
                                { to: '/monitoring/dashboard', label: 'DashBoard' },
                                { to: '/monitoring/checkbalance', label: 'Check Balance' },
                                { to: '/monitoring/rawData', label: 'Raw Data' },
                                // { to: '/monitoring/scraperlogs', label: 'Logs' },
                                { to: '/monitoring/rechargeform', label: 'Recharge Form' }
                            ]}
                        />
                    )}
                    {currentLocation.indexOf('/configuration') === 0 && (
                        <TabBar
                            items={[

                                { to: '/configuration/serviceprovider', label: 'Service Provider' },
                                { to: '/configuration/plan', label: 'Plan' },
                                { to: '/configuration/protocols', label: 'Protocols' },
                                { to: '/configuration/folders', label: 'Folders' },
                                { to: '/configuration/account', label: 'Account' },
                                { to: '/configuration/metadata/meta-data', label: 'Meta Data' },
                                { to: '/configuration/tags', label: 'Tags' },
                                ...(decodedData && decodedData.role == 'admin' ? [{ to: '/configuration/server', label: 'Server List' }] : []),
                                // ...(decodedData && decodedData.role == 'admin' ? [{ to: '/configuration/simdetails', label: 'Sim Details' }]:[]),
                                ...(decodedData && decodedData.role == 'admin' ? [{ to: '/configuration/triggerDevice', label: 'Trigger Device' }] : []),
                                ...(decodedData && decodedData.role == 'admin' ? [{ to: '/configuration/roles', label: 'Roles' }] : []),
                                ...(decodedData && decodedData.role == 'admin' ? [{ to: '/configuration/recharge/list', label: 'Recharge List' }] : []),
                            ]}
                        />
                    )}
                    {currentLocation.indexOf('/configuration/metadata') === 0 && (
                        <TabBar
                            items={[
                                { to: '/configuration/metadata/meta-data', label: 'Meta Data' },
                                { to: '/configuration/metadata/metadatatemplate', label: 'Metadata Template' },

                            ]}
                        />
                    )}
                    {currentLocation.indexOf(`/singledevicedetail/sim/datausage`) === 0 && (
                        <TabBar
                            items={[
                                { to: `/singledevicedetail/sim/datausage/graph/${id}`, label: 'Remaining Data Graph', name: 'datausage' },
                                {to: `/singledevicedetail/sim/datausage/dailyusage/${id}`, label: 'Daily Usage Graph', name: 'daily usage detail' },
                                // { to: `/singledevicedetail/sim/datausage/detail/${id}`, label: 'Remaining Data Detail', name: 'data usage detail' },
                            ]}
                        />
                    )}
                </div>
            </div>
        </div>
    );
})

export default NavBar
