import React, { useState } from "react";
import "./Table.css";
import moment from "moment"

export const DataTable2 = ({ headers, data}) => {
	const [collapseTable,setCollapseTable] = useState(false)

	const toggleTable = () => {
    setCollapseTable(!collapseTable)
  }

 data = data.map((data,index)=>{

	let SN = index + 1
 let Terminal = data.terminal
 let Received = moment.utc(data.received).format('YYYY-MM-DD HH:mm:ss')
 let Beam = data.beam
 let LastResetReason = data.lastresetreason

	return {SN,Terminal,Received,Beam,LastResetReason}
})

	return (
		<div className="data-table-container-rawdata">
			{/* {title && <h1>{title}</h1>} */}
			<table className="data-table-rawdata">
				<thead >
				 <tr onClick={toggleTable} style={{cursor:'pointer'}} >
						{headers.map((title, index) => (
							<th key={index}>{title}</th>
						))}
					</tr>
				</thead>

				<tbody>
					{!collapseTable && data &&
						data.map((data, index) => (
							<tr key={index}  >
								{headers.map((h, i) => (
									<td
										style={{ padding: "1px",paddingLeft:"5px" }}
										key={i}
									>
										{data[h]}
									</td>
								))}
							</tr>
						))}
				</tbody>
			</table>
		</div>
	);
};
