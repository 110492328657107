import React, { useContext, useEffect, useState } from 'react'
import "./MetaData.css"
import Tags from '../Tags/Tags'
import Form, { FormBody, FormActions, TextInput, SelectInput } from '../Common/Form';
import { useParams, useNavigate } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import { RootContext } from "../../index";
import { toast } from "react-toastify";
import { token } from '../../libs/localStorage';

const MetaData = observer(() => {
  const rootStore = useContext(RootContext);
  const deviceStore = rootStore.deviceStore;
  const simDetailStore = rootStore.simDetailStore
  const tagStore = rootStore.tagStore
  const uiStore = rootStore.uiStore

  const [deviceData, setDeviceData] = useState([]);
  const [simDetails, setSimDetails] = useState({
    "DeviceId": "",
    "status":"",
    "puk1": "",
    "puk2": "",
    "pin1": "",
    "pin2": "",
    "purchasedate": "",
    "provider": "",
    "balance_threshold": "",
    "volume_threshold": "",
    "remaining_balance":"",
    "remaining_volume":"",
    "station_name":""
  });
  const [templateValue, setTemplateValue] = useState([]);
  const [deviceTags, setDeviceTags] = useState([])
  const [tags, setTags] = useState([])
  const [selectedId,setSelectedId] = useState("1")

  const { id } = useParams();
  const navigate = useNavigate();
  const [showTemplate,setShowTemplate ]= useState(true)
  const tokenDecoded = token.getDecoded();


  useEffect(() => {
    (async () => {
      await deviceStore.fetchDeviceByDeviceId(id);
      await simDetailStore.fetchSimDetailsByPhoneNumber(id)
      await tagStore.fetchMetaDataTemplate()
      await tagStore.fetchTagsById(id)
      await tagStore.fetchMetaDataTemplateMetaDataById2(selectedId)

      const data = deviceStore.deviceByDeviceIdDetails
      const simData = simDetailStore.details5
      const deviceTag = tagStore.device_tags

      setDeviceData(data[0])
      setSimDetails(simData[0])
      setDeviceTags(deviceTag)

      const data1 = tagStore.meta_data_template_meta_data2
  
      let value = 1
      await tagStore.fetchDeviceMetaData(value,simData[0].device_id, deviceData.meta_data_template_id)
      const data2 = tagStore.deviceMetaData
      setTemplateValue(data1)
  
      //Match the name and render the value in template
      setTemplateValue(prevTemplateValue => {
        const updatedTemplateValue = prevTemplateValue.map(item => {
          const matchedMetaData = data2.find(metaItem => metaItem.name == item.name);
  
          if (matchedMetaData) {
            return { ...item, value: matchedMetaData.value };
          }
          return item;
        });
        return updatedTemplateValue;
      });

    })()
  }, [id]);


  const handleMetaDataChange = async (e) => {
    const { name, value } = e.target;

    if (value != "") {
      await tagStore.fetchMetaDataTemplateMetaDataById2(value)

      setShowTemplate(true)
    } else {
      // setShowTemplate(false)
      setSelectedId("")
      return
    }
    const data = tagStore.meta_data_template_meta_data2
    setSelectedId(value)

    await tagStore.fetchDeviceMetaData(value, simDetails.device_id, deviceData.meta_data_template_id)
    const data2 = tagStore.deviceMetaData
    setTemplateValue(data)

    //Match the name and render the value in template
    setTemplateValue(prevTemplateValue => {
      const updatedTemplateValue = prevTemplateValue.map(item => {
        const matchedMetaData = data2.find(metaItem => metaItem.name == item.name);

        if (matchedMetaData) {
          return { ...item, value: matchedMetaData.value };
        }
        return item;
      });
      return updatedTemplateValue;
    });
    setShowTemplate(true)
  };

  const handleSave = async (e) => {
    e.preventDefault();

    const deviceId = simDetails.device_id
    await simDetailStore.editSimDetails(deviceId, simDetails)
    await tagStore.editDeviceMetaData(deviceId, templateValue)

    if (tags.empty || tags.length) {
      await tagStore.editDeviceTags(id, tags)
    }

    //After success
    await deviceStore.fetchdeviceTags()
    toast.success("Success")
  }

  const handleDelete = async (e) => {
    // e.preventDefault();
    const confirm = window.confirm("Are you sure you want to delete this?");
    if (!!confirm) {
      const returnedId = await deviceStore.deleteDevice(id)
      if (!!returnedId) {
        toast.success("Success")
        navigate("/monitoring/dashboard");
      } else {
        toast.error("Failed")
      }
    }

  }
//
  const setNewForm = (e) => {
    const { name, value } = e.target;
    setSimDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleTemplateValueChange = (e) => {
    const { name, value } = e.target;
    setTemplateValue(prev => {
      return prev.map(item => {
        if (item.name == name) {
          return { ...item, value: value };
        }
        return item;
      });
    });
  };

  const handleTagsChange = (updatedTags) => {
    if (updatedTags.length == 0) {
      setTags({ empty: true })
    } else {
      setTags(updatedTags)
    }
  };

  return (
    <div>
      <div className='inline-grid space-between' style={uiStore.isNavOpen  ? {} : {marginLeft:'10px'}}>
        <div>
          <Form>
            <FormBody>
              <TextInput label={'DeviceId'} name={'DeviceId'} value={simDetails?.device_id} readOnly />
              <TextInput label={'Status'} name={'status'} value={simDetails?.status} readOnly />
              <TextInput label={'Balance'} value={simDetails?.remaining_balance} readOnly />
              <TextInput label={'Volume'} value={simDetails?.remaining_volume} readOnly />
              <TextInput label={'Station Name'} name={'station_name'} value={simDetails?.station_name} onChange={setNewForm} />
              <TextInput label={'Puk1'} name={'puk1'} value={simDetails?.puk1} onChange={setNewForm} />
              <TextInput label={'Puk2'} name={'puk2'} value={simDetails?.puk2} onChange={setNewForm} />
              <TextInput label={'Pin1'} name={'pin1'} value={simDetails?.pin1} onChange={setNewForm} />
              <TextInput label={'Pin2'} name={'pin2'} value={simDetails?.pin2} onChange={setNewForm} />
              <TextInput label={'PurchaseDate'} name={'purchasedate'} value={simDetails?.purchasedate} onChange={setNewForm} />
              <TextInput label={'Loginpassword'} name={'loginpassword'} value={simDetails?.loginpassword} onChange={setNewForm} />
              <TextInput label={'Provider'} name={'provider'} value={simDetails?.provider} onChange={setNewForm} />
              <TextInput label={'Balance Threshold'} name={'balance_threshold'} value={simDetails?.balance_threshold} onChange={setNewForm} />
              <TextInput label={'Volume Threshold'} name={'volume_threshold'} value={simDetails?.volume_threshold} onChange={setNewForm} />
              <TextInput label={'Email'} name={'email'} value={simDetails?.email} onChange={setNewForm} />
            </FormBody>
           
            <SelectInput
              label={'Meta Data Template'}
              fields={{ label: 'caption', value: 'id' }}
              options={tagStore.meta_data_template}
              onChange={handleMetaDataChange}
              value={selectedId }
            />
            <br /><br />
            <button style={{ maxWidth: "60%" }} onClick={() => navigate("/configuration/metadata/metadatatemplate")}>Create/Edit Template</button>

            <FormBody>
  {showTemplate && templateValue && templateValue
    .slice()
    .sort((a, b) => a.seq < b.seq ? -1 : 1)
    .map((item, i) => {
      const isLink = item.value && item.value.startsWith('http');
      const inputStyle = isLink ? { color: 'blue' } : {};
      return (
        <div key={`device-${id}-meta-data-${item.metadataid}-${item.name}-${i}`}>
          {isLink ? (
           
              <TextInput
               label={<a href={item.value}  target="_blank" rel="noopener noreferrer" style={inputStyle} >{item.name}</a>}
                // label={item.name}
                value={item.value}
                name={item.name}
                onChange={(e) => handleTemplateValueChange(e)}
              />
           
          ) : (
            <TextInput
              label={item.name}
              value={item.value}
              name={item.name}
              onChange={(e) => handleTemplateValueChange(e)}
            />
          )}
        </div>
      );
    })}
</FormBody>


            <br />
            <button style={{ maxWidth: "30%" }} onClick={handleSave}>Save</button>
          </Form>
        </div>
      
        <div style={{ width: '500px' }}>
          <Tags deviceTag={deviceTags} onUpdateTags={handleTagsChange} />
        </div>
      </div>
      {tokenDecoded && tokenDecoded.role == 'admin' ? (<button className='delete-number' style={{ minWidth: "10%",background:'red' }} onClick={handleDelete}>Delete</button>):(null)}
    </div>
  )
})

export default MetaData