import React, { useEffect, useState, useContext } from 'react'
import { observer } from "mobx-react-lite";
import { RootContext } from "../../index.js";
import { DateInput } from "../Common/Form.js"
import moment from "moment"
import { useParams } from "react-router-dom";
import SimGraph from './simGraph.js';
import DailyUsageGraph from './DailyUsageGraph.js';
require ("moment-timezone");

const DailyUsageSim = () => {
  const rootStore = useContext(RootContext);
  const dataUsageStore = rootStore.dataUsageStore
  

  const { id } = useParams();
  const [finalValue,setFinalValue] = useState([])
    
  useEffect(() => {
    const fetchData = async() => {
     const data =  await dataUsageStore.fetchSimUsageById(id);
      setFinalValue(data)
    }
    fetchData()
  
  }, [dataUsageStore,id]);

  const [filters, setFilters] = useState({
    date_from: moment().subtract(3, "days").format("YYYY-MM-DD 00:00:00"),
    date_to: moment().format("YYYY-MM-DD 23:59:59")
  });

  const setNewFilters = (filter, value) => {
    const dateTimeValue = moment.utc(value).startOf('day').toISOString();
    setFilters((prev) => ({ ...prev, [filter]: dateTimeValue }));
  };

  const fromDate = moment(filters.date_from).subtract(1, "day").format('YYYY-MM-DDT00:00:00');
  const toDate = moment(filters.date_to);


  let data = finalValue && finalValue.filter((item) => {
    let dataTime = moment.utc(item.created_date);
    return dataTime.isBetween(fromDate, filters.date_to, null, '[]');
  }).sort((a,b)=> a.id-b.id);


  let graphValue = []
  if (toDate.diff(fromDate, 'days') > 0) {
    let balance_array = []
    let volume_array = []
    let date_array = []

    for (let i=0;i<data.length;i++){
      if (i == 0) {
       continue;
      } else {
        if (data[i].remaining_balance){
          balance_array.push(Math.abs(data[i].remaining_balance - data[i-1].remaining_balance).toFixed(2))
        }
        
        if (data[i].remaining_volume) {
          volume_array.push(Math.abs(data[i].remaining_volume - data[i-1].remaining_volume).toFixed(2))
        }

        if (data[i].created_date) {
          date_array.push((moment.utc(data[i].created_date).tz('Asia/Kathmandu')).format('YYYY-MM-DD HH:mm:ss'))
        }
      }
    }

    const title = 'Daily Usage Graph'
 
    graphValue = [date_array,balance_array,volume_array,title]
  } 


  return (
    <div className='datausage'>
      <div >
        <DateInput
          label={"Date From"}
          type="date"
          value={moment(filters.date_from).format("YYYY-MM-DD")}
          onChange={(e) => setNewFilters("date_from", e.target.value)}
        />
        <DateInput
          label={"Date To"}
          type="date"
          value={moment(filters.date_to).format("YYYY-MM-DD")}
          onChange={(e) => setNewFilters("date_to", e.target.value)}
        />
      </div>

    <br/><br/>
     <DailyUsageGraph graphData={graphValue}  />
    </div>


  )
}

export default DailyUsageSim