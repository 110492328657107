import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';


const SimGraph = ({graphData}) => {
  // const { id } = useParams();
  if (graphData.length === 0) {
    return <div style={{color:"red", marginLeft:"700px", marginTop:"300px"}}>No Data</div>;
  }

  const options = {
    chart: {
      type: 'spline',
    },
    title: {
      text: graphData[3],
    },
    subtitle: {
      text: 'SIM',
    },
    xAxis: {
      categories:graphData[0],
      crosshair: true,
      accessibility: {
          description: 'Countries'
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Values',
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      shared: true
    },
    plotOptions: {
      column: {
          pointPadding: 0.2,
          borderWidth: 0
      }
  },
    series: [
      {
        name:"Balance",
        data:graphData[1].map(Number)
      },
      {
        name:"Volume",
        data:graphData[2].map(Number)
      },
    ],
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}

export default SimGraph