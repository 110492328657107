import React, { useState,useContext } from "react";
import "../../Common/DataTable/DataTable.css";
import { TablePagination} from "@mui/material";
import {useNavigate } from "react-router-dom";
import { RootContext } from "../../../index";

export const ScraperTable = ({
  headers,
  data,
  title,
}) => {
  const rootStore = useContext(RootContext);
  const uiStore = rootStore.uiStore;
  const navigate = useNavigate()
  const [rowsPerPage, setRowsPerPage] = useState(100); 
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  console.log(data,"data");

  const pageRedirect = (e) => {
    const url = `${window.location.origin}/#/singledevicedetail/sim/metadata/${e}`;
    window.open(url, "_blank"); 
    }

    const handleSearchChange = (event) => {
      console.log(event.target.value,"event");
      setSearchTerm(event.target.value);
      setPage(0); // Reset to first page on search
    };
  
    // console.log(data,"data");
    const filteredData = data.filter(item => 
      item.simnumber && item.simnumber.toLowerCase().includes(searchTerm.toLowerCase())
    );


  return (
    <div className="data-table-container" style={uiStore.isNavOpen ? undefined : {marginLeft:"30px"}}>
      <div style={{display:'flex', justifyContent:"space-between"}}>
      {title && <h1>{title}</h1>}
      {/* <input
          type="text"
          placeholder="Search by phone number"
          value={searchTerm}
          onChange={handleSearchChange}
          style={{ marginRight: "20px", maxHeight:"" }}
        /> */}
      <TablePagination
        rowsPerPageOptions={[5, 15, 25,50,100]}
        component="div"
        count={data ? data.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
      
      <table className="data-table">
        <thead>
          <tr>
            {headers.map((title, index) => (
              <th key={index}  style={{ width: title.width }}>{title.label}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {data &&
            data.sort((a, b) => b.observation_time.localeCompare(a.observation_time))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((data, index) => (
  
              <tr style={{cursor:"pointer"}} key={index} onClick={() => pageRedirect(data && data.simnumber )}>
                
                {headers.map((h, i) => (
                  <td style={{ padding: "5px",width: '10px' }} key={i}>
                    {data[h.key]}
                  </td>
                ))}
              
              </tr>
            ))}
        </tbody>
      </table>
     
    </div>
  );
};
