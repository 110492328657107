import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { RootContext } from "../..";
import { observer } from "mobx-react-lite";
import { DataTable } from "./Table";
import { DataTable2 } from "./Table2"
import { DateInput } from "../Common/Form";
import moment from "moment"
import "../RawData/rawData.css"
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";


const RawData = observer(() => {
  const rootStore = useContext(RootContext);
  const rawDataStore = rootStore.rawDataStore;
  const deviceStore = rootStore.deviceStore;

  const [filters, setFilters] = useState({
    date_from: moment().utc().subtract(6, "days").format("YYYY-MM-DDTHH:mm:ss"),
    date_to: moment().utc().format("YYYY-MM-DDT23:59:59")
  });

  const [inputFieldvalue, setinputFieldValue] = useState({
    mobileOwnerId: "",
    MobileId: ""
  })

  const [deviceInfo, setDeviceInfo] = useState({
    serialNumber: "",
  });
  const [data, setData] = useState([])
  const [decodedData, setDecodedData] = useState([])
  const [refTime, setRefTime] = useState([])

  useEffect(() => {
    rawDataStore.fetchOrbcommDevice();
    deviceStore.fetchGateWayAccount();
    rawDataStore.fetchReferenceTime();
  }, []);

  const setNewFilters = (filter, value) => {
    // const dateTimeValue = moment.utc(value).startOf('day').toISOString();
    setFilters((prev) => ({ ...prev, [filter]: value }));
  };


  const setNewForm = (e) => {
    const { name, value } = e.target;
    setinputFieldValue((prev) => ({ ...prev, [name]: value }));
  };

  const setNewForm2 = (e) => {
    const { name, value } = e.target;
    setDeviceInfo((prev) => ({ ...prev, [name]: value }));
  };



  const retrieveData = async (e) => {
  e.preventDefault();
    const { mobileOwnerId, MobileId } = inputFieldvalue
    let obj = {
      datefrom:filters.date_from,
      dateto:filters.date_to,
      mobileOwnerId:mobileOwnerId,
      mobileid:MobileId
    }
    await rawDataStore.fetchRawData(obj);
    await rawDataStore.fetchDecodedData(obj);
    await rawDataStore.fetchReferenceTime(obj)

    let filteredData = rawDataStore.items.sort((a, b) => new Date(b.receive_utc) - new Date(a.receive_utc))
    let filteredData2 = rawDataStore.items2.sort((a, b) => new Date(b.received) - new Date(a.received))
    let referencetime = rawDataStore.time

    setData(filteredData);
    setRefTime(referencetime)
    setDecodedData(filteredData2);

  }

  return (
    <div className="container">
      <div className="header-container">

        {/* <div className="logo-1">
        <img src={logo} alt="Logo" className="logo"/>
        </div> */}

        <div className="form-1">
          <form className="Form-0">
            <FormControl>
              <Select
                defaultValue="Account"
                name="mobileOwnerId"
                onChange={setNewForm}
                displayEmpty
                sx={{
                  height: 30,
                  backgroundColor: "white",
                  margin: 2
                }}
              >
                <MenuItem disabled value="Account"> GateWay Account</MenuItem>
                {deviceStore.gateWayAccount.map((account, index) => (
                  <MenuItem key={index} value={account.accountid}>{account.accountid}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl>
              <Select
                defaultValue="Account"
                name="MobileId"
                onChange={setNewForm}
                displayEmpty
                sx={{
                  height: 30,
                  backgroundColor: "white",
                  margin: 2
                }}
              >
                <MenuItem disabled value="Account"> Serial Number</MenuItem>
                {rawDataStore.orbcommDevice.map((device, index) => (
                  <MenuItem key={index} value={device}>{device}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <button onClick={retrieveData}>Retrieve Data</button>

            <br />
          </form></div>

        {/* <div className="time-1">{currentDate()}</div> */}


      </div>
      <div className="Date">
        <DateInput
          label={"Date From"}
          type="date"
          value={moment(filters.date_from).format("YYYY-MM-DD")}
          onChange={(e) => setNewFilters("date_from", e.target.value)}
        />
        <DateInput
          label={"Date To"}
          type="date"
          value={moment(filters.date_to).format("YYYY-MM-DD")}
          onChange={(e) => setNewFilters("date_to", e.target.value)}
        />
      </div>
      <br />
      <br />

      <div>
        <DataTable2
          title="Decoded Data"
          data={decodedData}
          headers={["SN", "Terminal", "Received", "Beam", "LastResetReason"]}
        />
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <div >
        <DataTable
          title="User Data"
          data={data}
          refTime={refTime}
          headers={["SN", "Mobileid", "Received", "Size", "SIN", "Region", "MobileOwnerId", "UserData","ObservationTime"]}
          retrieveData = {retrieveData}
        />
      </div>

    </div>
  )
})

export default RawData