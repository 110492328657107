import {jwtDecode} from "jwt-decode";

const AUTH_KEY = 'auth_key';
const LOCAL_STORAGE = 'ORBCOMM_STORAGE';

export const setToLocalStorage = (key, value) => localStorage.setItem(key, value);
export const getFromLocalStorage = (key) => localStorage.getItem(key);
export const removeFromLocalStorage = (key) => localStorage.removeItem(key)

export const getLocalStorage = () => !!window.localStorage[LOCAL_STORAGE] ? JSON.parse(window.localStorage[LOCAL_STORAGE]) : {};
export const setLocalStorage = (key, value) => window.localStorage[LOCAL_STORAGE] = JSON.stringify(Object.assign(getLocalStorage(), { [key]: value }));
export const getFromStorage = key => !!getLocalStorage() ? getLocalStorage()[key] : null;

export const token = {
    get: () => getFromLocalStorage(AUTH_KEY),
    set: (token) => setToLocalStorage(AUTH_KEY, token),
    remove: () => removeFromLocalStorage(AUTH_KEY),
    getDecoded: () => {
        try {
          const token = getFromLocalStorage(AUTH_KEY);
          return token ? jwtDecode(token) : null;
        } catch (err) {
          console.error('Error decoding token:', err);
          return null;
        }
      },
}